// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-best-country-exchange-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/bestCountryExchange.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-best-country-exchange-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-best-crypto-wallet-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/bestCryptoWallet.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-best-crypto-wallet-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-comparison-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/comparison.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-comparison-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-review-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/review.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-review-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-wallet-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/wallet.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-wallet-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/authors.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/contact.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-cookie-policy-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/cookie-policy.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-cookie-policy-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-privacy-policy-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/privacy-policy.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-privacy-policy-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-terms-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/terms.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-terms-jsx" */)
}

